<template>
  <div v-if="links.length > 0" class="c-teamlinks">
    <button v-bind:class="{ selected: selected }" type="button" @click="toggle">
      <span class="accessible-hide" v-if="team === 'home'"
        >Open/Close {{ this.$root.gameday.homeTeam.title }} Team Links</span
      >
      <span class="accessible-hide" v-if="team === 'away'"
        >Open/Close {{ this.$root.gameday.awayTeam.title }} Team Links</span
      >
      <feather size="18px" v-if="!selected" type="plus"></feather>
      <feather size="18px" v-if="selected" type="x"></feather>
    </button>
    <ul class="reset-list" v-if="selected && links.length > 0">
      <template v-for="(link, index) in links">
        <li v-if="link.url" :key="index">
          <a :href="link.url">{{ link.title }}</a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TeamLinks",
  data: () => ({
    selected: false,
  }),
  props: {
    links: {
      type: Array,
      required: true,
    },
    team: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggle() {
      this.selected = !this.selected;
    },
  },
};
</script>

<style lang="scss">
.c-teamlinks {
  position: relative;
  ul {
    position: absolute;
    z-index: 10;
    top: 38px;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    background: #fff;
    li {
      a {
        color: #000;
        text-align: left;
        text-decoration: none;
        display: block;
        padding: 12px;
        background: #fff;
        box-shadow: inset 0px -1px 0px #f1f1f1;
        transition: 0.25s;
        &:hover {
          box-shadow: inset 0px -1px 0px $color-primary;
          background: $color-primary;
          color: $text-on-primary;
          transition: 0.25s;
        }
      }
    }
  }
  button {
    cursor: pointer;
    padding: 0;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    border: none;
    color: #000;
    margin: 24px auto;
    transition: 0.25s;
    &:hover,
    &.selected {
      transition: 0.25s;
      background: $color-primary;
      color: $text-on-primary;
    }
  }
}
</style>
